import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'

import * as styles from '@/components/Kitchen/Main/Main.module.scss'
import MediaBreak from '@/components/Parts/MediaBreak'
import LuxuryLogoSVG from '@/images/kitchen/luxury_icon.svg'

const KitchenMain = () => {
  return (
    <section className={styles['main']}>
      <div className={styles['container']}>
        <h2 className={styles['title']}>
          あなたの第２のお店、
          <br />
          ここではじめてみませんか？
        </h2>
        <p className={styles['description']}>
          少しでも出店者の負担を減らすことでハードルを下げ、挑戦する人の背中を押したい、そんな想いから１階のキッチンスペースを貸し出しています。
          <br />
          <MediaBreak media='mobile' />
          普段のメニューをそのまま提供してもよし、いつもと違うジャンルの料理にチャレンジしてもよし。ここで提供されているのは、様々なバックグラウンドを持つ人々による個性豊かな料理たちです。
          <br />
          <MediaBreak media='mobile' />
          まずは1日、あなたならではのアイデアと腕で、鯖江に集う人々の日常を味わい深く彩ってみてはいかがでしょうか。
        </p>
      </div>
      <div className={styles['imageWrap']}>
        <StaticImage
          className={styles['image']}
          src='../../../images/kitchen/concept.png'
          alt='Concept'
          placeholder='blurred'
        />
      </div>
      <div className={styles['linkWrap']}>
        <Link to='/luxury' className={styles['link']}>
          <span>ラグジュアリーシェアキッチン</span>
          <img
            className={styles['luxuryLogo']}
            src={LuxuryLogoSVG}
            alt='Luxury'
          />
        </Link>
      </div>
    </section>
  )
}

export default KitchenMain
